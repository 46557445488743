import React, { useContext, useEffect, useState } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'

import AppContext from '../context/app'
import useGetSiteMetadata from '../hooks/useGetSiteMetadata'
import useGetAllProduct from '../hooks/useGetAllProduct'
import CartItem from './cart-item'
import Subtotal from './subtotal'
import PromoCode from './promo-code'
import FreeCheckout from './checkout-free'

import ESW_CLASSNAMES from '../esw/classnames'
import cancelicon from '../images/svgs/cancel-icon-black.svg'
import creditcard from '../images/svgs/credit-card-icon-black.svg'

import '../styles/animations/animate-cart.scss'
import '../styles/animations/animate-cart-background.scss'

export default function Cart() {
    const NAV_HEIGHT = 54

    const { cart, showCart, setShowCart, country, screenWidth } = useContext(AppContext)
    const { brandName } = useGetSiteMetadata()
    const allProduct = useGetAllProduct()

    const [isFreeCheckout, setIsFreeCheckout] = useState(false)
    const [showFreeCheckout, setShowFreeCheckout] = useState(false)

    const somePromotion = cart?.items?.some(item => {
        const { node } = allProduct.find(({ node }) => node.productId === item.product_id)
        const price = node.prices.find(_ => _.iso === country.node.country_iso_code)
        return price?.promotion ? true : false
    })

    const everyItemHasPrice = cart?.items?.every(item => {
        const { node } = allProduct.find(({ node }) => node.productId === item.product_id)
        const price = node.prices.find(_ => _.iso === country.node.country_iso_code)
        return price
    })

    useEffect(() => {
        if (typeof document === 'undefined') return
        if (showCart) document.body.classList.add('overflow-hidden')
        else document.body.classList.remove('overflow-hidden')
    }, [showCart])

    useEffect(() => {
        const total_cart_price = parseFloat(cart?.total_cart_price)
        const total_discount = parseFloat(cart?.total_discount)
        
        if (isNaN(total_discount)) {
            setIsFreeCheckout(false)
            return
        }
        
        if ((total_cart_price - total_discount) === 0) {
            setIsFreeCheckout(true)
        } else {
            setIsFreeCheckout(false)
        }
    }, [cart])

    if (!showCart) return
    return (
        <>
            {/* THE CART (UNDER THE NAVBAR) */}
            <Container fluid
                onClick={() => { if (!cart?.items) setShowCart(false) }}
                className='position-fixed start-0 bg-light animate-cart overflow-auto'
                style={{
                    top: NAV_HEIGHT,
                    height: screenWidth < 768 ? `calc(100vh - ${NAV_HEIGHT}px)` : `70vh`
                }}
            >
                {
                    showFreeCheckout
                        ?
                        <FreeCheckout
                            setShowFreeCheckout={setShowFreeCheckout}
                        />
                        :
                        <Container>
                            <p className='ff-primary fw-bold my-3'>CART</p>

                            {/* CART ITEMS */}
                            <Row className='overflow-auto' style={{ maxHeight: screenWidth < 768 ? '40vh' : '30vh' }}>
                                <Col>
                                    {cart?.items ?
                                        cart.items.map(item => <CartItem key={`cart-item-${item.product_id}`} item={item} />) :
                                        <p className='text-secondary mb-0' style={{ fontSize: 24 }}>Your cart is empty</p>}
                                </Col>
                            </Row>

                            {
                                cart?.items &&
                                <>
                                    {/* TOTAL */}
                                    <Row className='justify-content-end my-3'>
                                        <Col lg={8}>
                                            <div className='border-bottom border-dark'>
                                                <Row>
                                                    <Col>
                                                        <p className='fw-bold' style={{ fontSize: 18 }}>Total:</p>
                                                    </Col>
                                                    <Col>
                                                        <Stack direction='horizontal' gap={2} className='align-items-center'>
                                                            <span className={`${ESW_CLASSNAMES.CART_TOTAL} fw-bold`} style={{ fontSize: 22 }}>
                                                                {
                                                                    (country && cart) &&
                                                                    `${country.node.currency_symbol}${Number(cart?.total_discount ? cart.total_cart_price - cart.total_discount : cart.total_cart_price).toFixed(2)}`
                                                                }
                                                            </span>
                                                            {
                                                                ((somePromotion || cart?.total_discount) && everyItemHasPrice) &&
                                                                <s
                                                                    className={`${ESW_CLASSNAMES.CART_SUBTOTAL} fw-semibold text-secondary`}
                                                                    style={{ fontSize: 18 }}
                                                                >
                                                                    <Subtotal />
                                                                </s>
                                                            }
                                                        </Stack>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <PromoCode />
                                            <p className='fw-medium mb-2' style={{ fontSize: 14 }}>
                                                Checkout with ESW
                                            </p>
                                            <p className='fw-medium' style={{ fontSize: 14 }}>
                                                By clicking ‘PROCEED TO CHECKOUT’, you will be redirected to the ESW checkout page where payment will be taken and your order fulfilled by ESW, {brandName}’s preferred partner for international sales.
                                            </p>
                                            <p className='fw-medium' style={{ fontSize: 14 }}>
                                                Important - AIR now uses <a href="https://profile.inmusicbrands.com/" target="_blank" rel="noreferrer" className='text-black text-decoration-underline'>inMusic Profile</a> to manage your purchases. To link your purchase please enter your inMusic Profile email in the cart. If you do not have one one will be created for you.
                                            </p>
                                        </Col>
                                    </Row>

                                    {/* CONTINUE BROWSING / PROCEED TO CHECKOUT */}
                                    <Row className='justify-content-end pb-5'>
                                        <Col md='auto'>
                                            <Button
                                                variant=''
                                                onClick={() => setShowCart(false)}
                                                className='text-uppercase ff-primary fw-medium flex-center gap-1 border border-black mb-3 mb-md-0'
                                                style={{ fontSize: 18, width: 290 }}
                                            >
                                                <img src={cancelicon} alt='' />
                                                Continue Browsing
                                            </Button>
                                        </Col>
                                        <Col md='auto'>
                                            {isFreeCheckout && <Button
                                                variant=''
                                                className={`text-uppercase ff-primary fw-medium flex-center gap-1 border border-primary bg-primary`}
                                                style={{ fontSize: 18, width: 290 }}
                                                onClick={() => setShowFreeCheckout(true)}
                                            >
                                                Register My Product
                                            </Button>}
                                            
                                            {/*  
                                                ELEMENT MUST NOT BE REMOVED FROM DOM FOR ESW
                                                JUST CHANGE DISPLAY TO NONE IF FREE CHECKOUT
                                            */}
                                            <Button
                                                variant=''
                                                className={`
                                                    ${isFreeCheckout ? 'd-none' : ''}
                                                    ${ESW_CLASSNAMES.CHECKOUT_BUTTON}
                                                    text-uppercase ff-primary fw-medium flex-center gap-1 border border-primary bg-primary
                                                `}
                                                style={{ fontSize: 18, width: 290 }}
                                                onClick={() => {
                                                    if (typeof window === `undefined`) return
                                                    const { singularSdk } = window
                                                    try{
                                                        singularSdk.event('sng_checkout_initiated', {
                                                            sng_attr_content_list: cart?.items.map(({ sku }) => sku),
                                                            sng_attr_quantity: cart?.items.length,
                                                            sng_attr_item_price: `${country?.node?.currency_symbol}${Number(cart?.total_discount ? cart?.total_cart_price - cart?.total_discount : cart?.total_cart_price).toFixed(2)}`,
                                                        })
                                                    } catch(e){
                                                        console.error(e)
                                                    }
                                                }}
                                            >
                                                <img src={creditcard} alt='' />
                                                Proceed To Checkout
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Container>
                }
            </Container>

            {/* LINEAR GRADIENT UNDER THE CART */}
            <Container fluid onClick={() => setShowCart(false)}
                className={`position-fixed start-0 bottom-0 animate-cart-background ${screenWidth < 768 ? 'invisible' : ''}`}
                style={{ background: `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.3))`, height: `calc(30vh - ${NAV_HEIGHT}px)` }}
            />
        </>
    )
}
