// https://support.singular.net/hc/en-us/articles/360039991491-Singular-Website-SDK-Native-Integration
module.exports = () => {
    if (typeof window === `undefined`) return
    const { singularSdk, SingularConfig } = window

    const sdkKey = 'inmusicstore_30b8e750'
    const sdkSecret = '3eecd764f3eaf1e4b55b56659f90b037'
    const productId = 'com.airmusictech'

    try{
        const config = new SingularConfig(sdkKey, sdkSecret, productId)
            .withInitFinishedCallback(initParams => {
                // This callback will be called when the init method has finished
    
                // You can get Singular Device id here
                const singularDeviceId = initParams.singularDeviceId
                const event = new CustomEvent('SINGULAR_INIT_FINISHED', { detail: singularDeviceId })
                window.dispatchEvent(event)
            })
        singularSdk.init(config)
    } catch(e){
        console.error(e)
    }
}
